<template>
  <v-toolbar-items>
    <v-btn
        small
        text
        :to="`${$route.query.plating === '1'?'?':'?plating=1'}`"
    >Plating
    </v-btn>
    <v-btn
        small
        text
        :to="`${$route.query.prep === '1'?'?':'?prep=1'}`"
    >
      Prep
    </v-btn>
  </v-toolbar-items>
</template>

<script>
export default {
  name: "MealButtons"
}
</script>

<style scoped>

</style>
